export const ENGLISH_LEVEL = [
  { name: 'A1', id: 1, checked: false },
  { name: 'A2', id: 2, checked: false },
  { name: 'B1', id: 3, checked: false },
  { name: 'B2', id: 4, checked: false },
  { name: 'C1', id: 5, checked: false },
  { name: 'C2', id: 6, checked: false },
]

export const EXPERIENCE_LEVEL = [
  { name: '0-3 ', id: 1 },
  { name: '3-6 ', id: 2 },
  { name: '6-10 ', id: 3 },
  { name: '+10 ', id: 4 },
]

export const EXPERIENCE_LEVEL_TWO = [
  { name: '0-3 años', id: 1, value: '1' },
  { name: '3-6 años', id: 2, value: '2' },
  { name: '6-10 años', id: 3, value: '3' },
  { name: 'Más de 10 años', id: 4, value: '4' },
]

export const LIST_OF_REALS_EMAILS = [
  { name: 'GMAIL' },
  { name: 'YAHOO' },
  { name: 'OUTLOOK' },
  { name: 'GMX' },
  { name: 'ZOHO' },
  { name: 'PROTON' },
  { name: 'TUTATONA' },
  { name: 'HOTMAIL' },
]

export const LIST_OF_OFFERS_STATUS = [
  { name: 'En proceso', id: 1, value: 'wait' },
  { name: 'Rechazados', id: 2, value: 'rejected' },
  { name: 'Aceptados', id: 3, value: 'accepted' },
  { name: 'Todos', id: 4, value: 'all' },
]

export const LIST_TYPE_OF_TALENTS = [
  { name: 'Talentos sin reclutador', id: 1, value: 'notR' },
  { name: 'Talentos con reclutador', id: 2, value: 'withR' },
  { name: 'Todos', id: 3, value: 'all' },
]

export const LIST_OF_TALENTS_VALIDATES = [
  { name: 'Talentos sin validar', id: 1, value: 'not-activated' },
  { name: 'Talentos validados', id: 2, value: 'activated' },
  { name: 'Todos', id: 3, value: '' },
]

export const LIST_OF_FILTER_TMS = [
  { name: 'Eliminados', id: 1, value: false },
  { name: 'Activos', id: 2, value: true },
  { name: 'Todos', id: 3, value: '' },
]
