import { useTranslation } from 'react-i18next'
import { useListOfTalentsBySuperSearchStore } from '../store'
import { useQuery } from '@tanstack/react-query'
import {
  getCategories,
  getListOfSkillsCategories,
  superSearchListOfTalents,
} from '../services/talents'
import { TAGS } from '../services/Tags'
import { useEffect, useState } from 'react'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import useResize from '../hooks/useResize'
import { VIEWPORT_LG } from '../utils/constants'
import Dropdown from './Dropdown/Dropdown'
import ArrowUpIcon from '../icons/ArrowUpIcon'
import ArrowDownIcon from '../icons/ArrowDownIcon'
import TuneIcon from '../icons/TuneIcon'

const SearchTalents = ({ isSuperSearch = false, showArrows = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const viewportSize = useResize()

  const {
    selectedCategories,
    setSelectedCategories,

    currentCategories,
    setCurrentCategories,

    filteredCategories,
    setFilteredCategories,

    selectedSkills,
    setSelectedSkills,

    currentSkills,
    setCurrentSkills,

    filteredSkills,
    setFilteredSkills,

    setPage: setGlobalPage,
    setData,
    setDataWithoutBanners,

    actionButton,
    setActionButton,
    id,
    setId,

    firstSearch,
    setFirstSearch,

    hideSearch,
    setHideSearch,
  } = useListOfTalentsBySuperSearchStore()

  const getOnlyCategoriesIds = selectedCategories?.map(({ id }) => id)
  const getOnlySkillsIds = selectedSkills?.map(({ id }) => id)

  const [searchTermCategory, setSearchTermCategory] = useState('')
  const [searchTermSkill, setSearchTermSkill] = useState('')

  const [showFirstDropdown, setShowFirstDropdown] = useState(false)
  const [showSecondDropdown, setShowSecondDropdown] = useState(false)

  const renderHideOrShowArrows = viewportSize[0] <= VIEWPORT_LG && showArrows

  const { data: categoriesData = [], isSuccess: isSuccessCategories } =
    useQuery([TAGS.categories], getCategories)

  const { data: skillsData = [], isSuccess: isSuccessSkills } = useQuery(
    [TAGS.skills_categories],
    getListOfSkillsCategories
  )

  const transformSkillsData = skillsData?.map(({ id, skill }) => {
    return {
      id,
      name: skill.name,
    }
  })

  const filterValue = ({ data, term }) => {
    return data?.filter(({ name }) =>
      name.toLowerCase().includes(term.toLowerCase())
    )
  }

  const handleDataChange = ({
    data = [],
    serviceData = [],
    searchTerm = '',
    id = null,
    setSelectedData = () => {},
    setCurrentData = () => {},
    setFilteredData = () => {},
  }) => {
    const updatedData = data?.map((value) => {
      if (value.id === id) {
        return { ...value, checked: !value.checked }
      }
      return value
    })

    const filteredData = filterValue({
      data: serviceData,
      term: searchTerm,
    })

    const selectedData = updatedData?.filter((value) => value.checked)
    setSelectedData(selectedData)
    setCurrentData(updatedData)
    setFilteredData(filteredData)
  }

  const handleDeleteData = ({
    data = [],
    id = null,
    setSelectedData = () => {},
    setCurrentData = () => {},
  }) => {
    const updatedData = data?.map((value) => {
      if (value.id === id) {
        return { ...value, checked: false }
      }
      return value
    })

    const selectedData = updatedData?.filter((value) => value.checked)
    setSelectedData(selectedData)
    setCurrentData(updatedData)
  }

  const getFirstTalentPositionId = async () => {
    if (id) {
      await setId(null)
    }

    const data = await superSearchListOfTalents({
      filters: {
        yearsExperience: '',
        talentSkills: getOnlySkillsIds ?? '',
        talentCategory:
          getOnlyCategoriesIds?.length === 0 ? '' : getOnlyCategoriesIds,
        talentLangLevel: '',
      },
      page: 0,
    })

    if (data?.info?.length === 0) return
    await setId(data?.info?.[0]?.id)
  }

  useEffect(() => {
    if (
      (isSuccessCategories && currentCategories?.length === 0) ||
      currentCategories?.length === 0
    ) {
      setFilteredCategories(
        filteredCategories?.length === 0 ? categoriesData : filteredCategories
      )
      setCurrentCategories(
        currentCategories?.length === 0 ? categoriesData : currentCategories
      )
    }
  }, [isSuccessCategories])

  useEffect(() => {
    if (
      (isSuccessSkills && currentSkills?.length === 0) ||
      filteredSkills?.length === 0
    ) {
      setCurrentSkills(
        selectedSkills?.length === 0 ? transformSkillsData : currentSkills
      )
      setFilteredSkills(
        filteredSkills?.length === 0 ? transformSkillsData : filteredSkills
      )
    }
  }, [isSuccessSkills])

  return (
    <div
      className={`${
        !isSuperSearch ? 'shadow super-search-container w-100' : ''
      }`}
    >
      <div
        className={
          !isSuperSearch
            ? 'p-3 d-flex flex-column flex-lg-row align-items-center gap-2'
            : 'd-flex flex-column flex-lg-row align-items-center gap-2'
        }
      >
        {renderHideOrShowArrows ? (
          hideSearch ? (
            <div
              className="d-flex align-items-center justify-between w-100"
              onClick={() => setHideSearch(false)}
            >
              <div className="d-flex align-items-center gap-1 w-100">
                <TuneIcon fill="#6B8282" />
                <span className="text-on-bg-secondary">{t('filters')}</span>
              </div>
              <div>
                <ArrowUpIcon width="26" height="26" fill="#004949" />
              </div>
            </div>
          ) : (
            <>
              <div
                className="d-flex align-items-center justify-between w-100"
                onClick={() => setHideSearch(true)}
              >
                <div className="d-flex align-items-center gap-1 w-100">
                  <TuneIcon fill="#6B8282" />
                  <span className="text-on-bg-secondary">{t('filters')}</span>
                </div>
                <div>
                  <ArrowDownIcon width="26" height="26" />
                </div>
              </div>
            </>
          )
        ) : (
          <></>
        )}
        {!hideSearch && (
          <>
            <Dropdown
              isSuperSearch={isSuperSearch}
              showDropdown={showFirstDropdown}
              handleShowDropdown={() =>
                setShowFirstDropdown((prevState) => !prevState)
              }
              handleOutside={() => setShowFirstDropdown(false)}
              searchTerm={searchTermCategory}
              setSearchTerm={setSearchTermCategory}
              selectedValues={selectedCategories}
              setSelectedValue={setSelectedCategories}
              currentValue={currentCategories}
              setCurrentValue={setCurrentCategories}
              filteredValue={filteredCategories}
              setFilteredValue={setFilteredCategories}
              data={categoriesData}
              handleDataChange={handleDataChange}
              handleDeleteData={handleDeleteData}
              filterValue={filterValue}
              text={t('search_by_category')}
            />
            <Dropdown
              isSuperSearch={isSuperSearch}
              showDropdown={showSecondDropdown}
              handleShowDropdown={() =>
                setShowSecondDropdown((prevState) => !prevState)
              }
              handleOutside={() => setShowSecondDropdown(false)}
              searchTerm={searchTermSkill}
              setSearchTerm={setSearchTermSkill}
              selectedValues={selectedSkills}
              setSelectedValue={setSelectedSkills}
              currentValue={currentSkills}
              setCurrentValue={setCurrentSkills}
              filteredValue={filteredSkills}
              setFilteredValue={setFilteredSkills}
              data={transformSkillsData}
              handleDataChange={handleDataChange}
              handleDeleteData={handleDeleteData}
              filterValue={filterValue}
              text={t('technical_skills')}
            />
            <Button
              className={`${
                !isSuperSearch ? 'px-5 py-2 text-white' : 'text-on-bg-primary'
              } btn-bg-CTA-primary super-search-field`}
              type="submit"
              disabled={
                selectedCategories?.length === 0 && selectedSkills?.length === 0
              }
              onClick={() => {
                if (
                  selectedCategories?.length === 0 &&
                  selectedSkills?.length === 0
                ) {
                  return
                }

                if (firstSearch === false) {
                  setFirstSearch(true)
                }

                if (viewportSize[0] > VIEWPORT_LG) {
                  getFirstTalentPositionId()
                }

                setActionButton(actionButton + 1)
                setGlobalPage(0)
                setData([])
                setDataWithoutBanners([])
                navigate('/super-search')
              }}
            >
              {t('see_talents')}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default SearchTalents
