import { useNavigate } from 'react-router-dom'
import HomeIcon from '../../../icons/HomeIcon'
import ProfileIcon from '../../../icons/ProfileIcon'
import { PRIVATE_ROUTES } from '../../../Routes/routes'
import SidebarButton from './SidebarButton'
import { typeUser } from '../../../localStorage'
import { LIST_OF_DASHBOARD_OPTIONS } from '../../../pages/Dashboard/constants'
import { TYPE_OF_USER, TYPE_PROFILE } from '../../../utils/constants'
import SmallBriefcaseIcon from '../../../icons/SmallBriefcaseIcon'
import SearchUserIcon from '../../../icons/SearchUserIcon'
import HandsIcon from '../../../icons/HandsIcon'
import { useChatStore } from '../../../store'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createChat, listOfChats } from '../../../services/Chat'
import { TAGS } from '../../../services/Tags'
import { useTranslation } from 'react-i18next'
import UserSearchIcon from '../../../icons/UserSearchIcon'
import BriefcaseIcon from '../../../icons/BriefcaseIcon'
import HouseAndBuildingIcon from '../../../icons/HouseAndBuildingIcon'
import ConfigIcon from '../../../icons/ConfigIcon'
import CallCenterIcon from '../../../icons/CallCenterIcon'
import EmailCheckedIcon from '../../../icons/EmailCheckedIcon'
// import RobotoIcon from '../../../icons/dashboard/RobotoIcon'

const Menu = ({ userData = {} }) => {
  const navigate = useNavigate()
  const { userInfo: profileUserInfo = {} } = userData
  const { steps_completed = false } = profileUserInfo || {}
  const { setOpen, setId } = useChatStore()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: listOfChatsData } = useQuery(
    [TAGS.list_of_chats],
    listOfChats,
    {
      enabled: profileUserInfo?.recruiter !== null,
    }
  )

  const getRecruiterChat = listOfChatsData
    ?.map(({ chat_users, id }) => {
      const data = chat_users?.find(
        ({ user }) => user?.typeProfile === TYPE_PROFILE?.recruiter
      )
      return {
        data,
        id,
      }
    })
    ?.filter((props) => props?.data !== undefined)

  const { mutate: createChatMutate } = useMutation(
    [TAGS.create_chat],
    createChat,
    {
      onSuccess: async ({ response, res }) => {
        if (response?.status === 200) {
          queryClient.invalidateQueries(TAGS.list_of_chats)
          setId(res?.chat)
        }
      },
    }
  )

  const handleGoToPersonalTalentInformation = () => {
    steps_completed
      ? navigate(PRIVATE_ROUTES.update_personal_information)
      : navigate(PRIVATE_ROUTES.talent_information)
  }

  const handleGoToProfessionalTalentInformation = () => {
    steps_completed
      ? navigate(PRIVATE_ROUTES.update_professional_information)
      : navigate(PRIVATE_ROUTES.talent_information)
  }

  const handleGoToCompanyInformation = () => {
    steps_completed
      ? navigate(PRIVATE_ROUTES.update_company_information)
      : navigate(PRIVATE_ROUTES.company_form)
  }

  const handleGoToPersonalCompanyInformation = () => {
    steps_completed
      ? navigate('/company-personal-information')
      : navigate(PRIVATE_ROUTES.company_form)
  }

  const TalentRoutes = () => (
    <>
      <div className="dropdown">
        <SidebarButton
          data-bs-toggle="collapse"
          href="#sidebar-collapse"
          Icon={() => <ProfileIcon />}
          title={t('my_profile')}
          className="mb-2"
          role="button"
          onClick={() => {}}
        />
        <div
          className="collapse show"
          id="sidebar-collapse"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            titleClassName="ms-2"
            title={t('staff')}
            onClick={handleGoToPersonalTalentInformation}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            titleClassName="ms-2"
            title={t('professional')}
            onClick={handleGoToProfessionalTalentInformation}
          />
        </div>
      </div>

      <SidebarButton
        Icon={() => <SmallBriefcaseIcon />}
        title={t('offers')}
        className="mb-2"
        onClick={() => navigate(PRIVATE_ROUTES.list_of_offers)}
      />
      {profileUserInfo?.recruiter && (
        <SidebarButton
          Icon={() => <HandsIcon fill="#E4F8ED" width="20" height="20" />}
          title={t('talk_to_your_talent_manager')}
          className="mb-2"
          onClick={() => {
            if (getRecruiterChat?.length !== 0) {
              setId(getRecruiterChat?.[0]?.id)
            }
            createChatMutate({
              idTargetUser: profileUserInfo?.recruiter?.user?.id,
            })
            setOpen(true)
          }}
        />
      )}

      {/* <SidebarButton
        Icon={() => <RobotoIcon />}
        title={t('chatgpt_assistant')}
        className="mb-2"
        onClick={() => navigate(PRIVATE_ROUTES.chatgpt_assistant)}
      /> */}
    </>
  )

  const RecruiterRoutes = () => (
    <>
      <SidebarButton
        title={t('my_profile')}
        onClick={() => navigate(PRIVATE_ROUTES.personal_data)}
        Icon={() => <ProfileIcon width="16" height="16" />}
        className="mb-2"
      />
      <SidebarButton
        className="mb-2"
        title={t('my_talents')}
        onClick={() => navigate(PRIVATE_ROUTES.tm_talents)}
        Icon={() => <UserSearchIcon />}
      />
      <div className="dropdown mb-2">
        <SidebarButton
          data-bs-toggle="collapse"
          href="#sidebar-collapse"
          Icon={() => <BriefcaseIcon width="16" height="16" />}
          title={t('offers')}
          className="mb-2"
          role="button"
          onClick={() => {}}
        />
        <div
          className="collapse show"
          id="sidebar-collapse"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            title={t('vacancies')}
            onClick={() => navigate(PRIVATE_ROUTES.all_offers_from_recruiter)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title={t('offers_received')}
            onClick={() => navigate(PRIVATE_ROUTES.offers_with_talents_tm)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title={t('active_processes')}
            onClick={() => navigate(PRIVATE_ROUTES.active_processes)}
          />
        </div>
      </div>

      {/* <SidebarButton
        className="sidebar-button-inside w-75"
        title={LIST_OF_DASHBOARD_OPTIONS.invite_talent}
        onClick={() => navigate(PRIVATE_ROUTES.invite_talent)}
      />
     
       <SidebarButton
        className="sidebar-button-inside w-75"
        title={LIST_OF_DASHBOARD_OPTIONS.search_talent}
        onClick={() => navigate(PRIVATE_ROUTES.talents)}
      />
      */}

      <SidebarButton
        title={t('my_enterprise')}
        onClick={() => navigate(PRIVATE_ROUTES.tm_companies)}
        Icon={() => <HouseAndBuildingIcon />}
      />
    </>
  )

  const CompanyRoutes = () => (
    <>
      <SidebarButton
        Icon={() => <SmallBriefcaseIcon />}
        title={t('offers')}
        titleClassName="text-on-bg-brand"
        onClick={() => navigate(PRIVATE_ROUTES.list_of_offers)}
      />
      <SidebarButton
        Icon={() => <SearchUserIcon />}
        onClick={() => navigate(PRIVATE_ROUTES.talents)}
        title={t('talents')}
      />
      <div className="dropdown">
        <SidebarButton
          Icon={() => <ProfileIcon />}
          title={t('my_profile')}
          data-bs-toggle="collapse"
          href="#sidebar-collapse-company"
          className="mb-2"
          role="button"
        />
        <div
          className="collapse show"
          id="sidebar-collapse-company"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            title={t('company_profile')}
            onClick={handleGoToCompanyInformation}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title={t('user_profile')}
            onClick={handleGoToPersonalCompanyInformation}
          />
        </div>
      </div>
    </>
  )

  const HiringAdvisorRoutes = () => (
    <>
      <SidebarButton
        className="sidebar-button-inside w-75"
        title={LIST_OF_DASHBOARD_OPTIONS.personal_data}
        onClick={() => navigate(PRIVATE_ROUTES.personal_data)}
      />
      <SidebarButton
        className="sidebar-button-inside w-75"
        title={LIST_OF_DASHBOARD_OPTIONS.my_companies}
        onClick={() =>
          navigate(PRIVATE_ROUTES.list_of_companies_by_hiring_advisor)
        }
      />
      <SidebarButton
        className="sidebar-button-inside w-75"
        onClick={() => navigate(PRIVATE_ROUTES.list_of_fast_offers_by_ha)}
        title={t('my_offers')}
      />
      <SidebarButton
        Icon={() => <SearchUserIcon />}
        onClick={() => navigate(PRIVATE_ROUTES.talents)}
        title={t('talents')}
      />
    </>
  )

  const AdminRoutes = () => (
    <>
      <div className="dropdown">
        <SidebarButton
          Icon={() => <SearchUserIcon width="16" height="16" />}
          title="Gestión de talentos"
          data-bs-toggle="collapse"
          href="#sidebar-collapse-admin"
          className="mb-2"
          role="button"
        />
        <div
          className="collapse"
          id="sidebar-collapse-admin"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Estadísticas"
            onClick={() => navigate(PRIVATE_ROUTES.dashboard_talents)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Total de talentos"
            onClick={() => navigate(PRIVATE_ROUTES.admin_talents)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title={t('experience')}
            onClick={() => navigate(PRIVATE_ROUTES.dashboard_experience)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Perfiles de talentos"
            onClick={() => navigate(PRIVATE_ROUTES.talents)}
          />
        </div>
      </div>

      <div className="dropdown">
        <SidebarButton
          Icon={() => <HandsIcon width="16" height="16" fill="#E4F8ED" />}
          title="Talent Managers"
          data-bs-toggle="collapse"
          href="#sidebar-collapse-talents-managers-admin"
          className="mb-2"
          role="button"
        />
        <div
          className="collapse"
          id="sidebar-collapse-talents-managers-admin"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Estadísticas"
            onClick={() => navigate(PRIVATE_ROUTES.dashboard_talents)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Lista completa"
            onClick={() => navigate(PRIVATE_ROUTES.admin_recruiters)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Hiring Advisors"
            onClick={() => navigate(PRIVATE_ROUTES.list_of_hiring_advisors)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Ranking"
            onClick={() =>
              navigate(PRIVATE_ROUTES.admin_talent_manager_ranking)
            }
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Última conexión"
            onClick={() =>
              navigate(PRIVATE_ROUTES.talent_manager_last_conection)
            }
          />
        </div>
      </div>

      <div className="dropdown">
        <SidebarButton
          Icon={() => (
            <HouseAndBuildingIcon width="16" height="16" fill="#E4F8ED" />
          )}
          title="Empresas"
          data-bs-toggle="collapse"
          href="#sidebar-collapse-companies-admin"
          className="mb-2"
          role="button"
        />
        <div
          className="collapse"
          id="sidebar-collapse-companies-admin"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Lista completa"
            onClick={() => navigate(PRIVATE_ROUTES.admin_companies)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Ofertas"
            onClick={() => navigate(PRIVATE_ROUTES.all_offers_from_recruiter)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Ofertas rápidas"
            onClick={() => navigate(PRIVATE_ROUTES.list_of_fast_offers)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Ticket de ofertas rápidas"
            onClick={() => navigate(PRIVATE_ROUTES.admin_quick_offer)}
          />
        </div>
      </div>

      <div className="dropdown">
        <SidebarButton
          Icon={() => <ConfigIcon />}
          title="Funcionalidades"
          data-bs-toggle="collapse"
          href="#sidebar-collapse-functionalities-admin"
          className="mb-2"
          role="button"
        />
        <div
          className="collapse"
          id="sidebar-collapse-functionalities-admin"
          aria-expanded="true"
        >
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Lista de habilidades"
            onClick={() => navigate(PRIVATE_ROUTES.list_Of_Skills)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Lista de habilidades blandas"
            onClick={() => navigate(PRIVATE_ROUTES.list_of_soft_skills)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Categorías"
            onClick={() => navigate(PRIVATE_ROUTES.list_Of_Categories)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Sectores"
            onClick={() => navigate(PRIVATE_ROUTES.list_Of_Sectors)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Estados"
            onClick={() => navigate(PRIVATE_ROUTES.list_of_states)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Ciudades"
            onClick={() => navigate(PRIVATE_ROUTES.list_of_city)}
          />
          <SidebarButton
            className="sidebar-button-inside w-75"
            title="Idiomas"
            onClick={() => navigate(PRIVATE_ROUTES.list_of_idioms)}
          />
        </div>
      </div>
      <SidebarButton
        Icon={() => <EmailCheckedIcon fill="#E4F8ED" />}
        className="mb-2"
        title="Generador de correos"
        onClick={() => navigate(PRIVATE_ROUTES.admin_send_emails)}
      />

      <SidebarButton
        Icon={() => <CallCenterIcon />}
        className="mb-2"
        title={LIST_OF_DASHBOARD_OPTIONS.support}
        onClick={() => navigate(PRIVATE_ROUTES.support)}
      />
    </>
  )

  const renderRoutes = {
    [TYPE_OF_USER.talent]: <TalentRoutes />,
    [TYPE_OF_USER.recruiter]: <RecruiterRoutes />,
    [TYPE_OF_USER.company]: <CompanyRoutes />,
    [TYPE_OF_USER.hiring_advisor]: <HiringAdvisorRoutes />,
    [TYPE_OF_USER.admin]: <AdminRoutes />,
  }

  return (
    <div id="sidebar-menu" className="menu-container">
      <li className="text-decoration-none menu-items-container text-white">
        <SidebarButton
          Icon={() => <HomeIcon width="16" height="16" />}
          title={t('home')}
          className="mb-2"
          onClick={() => navigate(PRIVATE_ROUTES.dashboard)}
        />
        {renderRoutes[typeUser]}
      </li>
    </div>
  )
}

export default Menu

//HandsIcon
