import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import {
  LIST_OF_PRIVATES_ROUTES,
  LIST_OF_PUBLIC_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
} from './routes'

import { RedirectRoute } from '../utils'
import Talents from '../pages/Talents'
import { Suspense, useEffect } from 'react'

import { LOCAL_STORAGE_VALUES, token } from '../localStorage'
import { emit, off, on, SOCKET_VALUES } from '../socket'
import Loader from '../components/Loader'
import { useQueryClient } from '@tanstack/react-query'
import notificationMessage from '../sounds/notificationMessage.mp3'
import { TAGS } from '../services/Tags'
import { OUT_OF_THE_APP } from '../utils/constants'
import dayjs from 'dayjs'
import LostInternetConection from '../pages/LostInternetConection/LostInternetConection'
import NotFound from '../pages/NotFound/NotFound'
import { useLostConectionStore } from '../store'
import lost_conection from '../assets/lost_conection.png'

const AppRouter = () => {
  const queryClient = useQueryClient()
  const { setLostConectionImage } = useLostConectionStore()

  const invitationCodeTimeExpired = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_VALUES.invitationCodeTimeExpired)
  )
  const audio = new Audio(notificationMessage)

  const invitationCodeTimeExpiredDate = dayjs(invitationCodeTimeExpired)
  const today = dayjs()
  const differenceInHours = today.diff(invitationCodeTimeExpiredDate, 'hours')
  const isOneWeekHoursDifference = Math.abs(differenceInHours) >= 168

  const renderPublicRoute = LIST_OF_PUBLIC_ROUTES.map(({ path, element }) => {
    return {
      path: path,
      element: <RedirectRoute isPrivate={false} Route={() => element} />,
    }
  })

  const renderPrivateRoute = LIST_OF_PRIVATES_ROUTES.map(
    ({ path, element }) => {
      return {
        path: path,
        element: <RedirectRoute Route={() => element} />,
      }
    }
  )

  const router = createBrowserRouter([
    ...renderPublicRoute,
    ...renderPrivateRoute,
    {
      path: PRIVATE_ROUTES.talents,
      element: <Talents />,
    },
    // lost conection route
    {
      path: '/internet-connection-lost',
      element: <LostInternetConection />,
    },
    // unknow route
    {
      path: '*',
      element: OUT_OF_THE_APP ? (
        <NotFound />
      ) : (
        <RedirectRoute
          isPrivate={false}
          Route={() => <Navigate to={PUBLIC_ROUTES.login} />}
        />
      ),
    },
  ])

  const handlePlaySound = () => {
    audio.play()
    return
  }

  useEffect(() => {
    emit(SOCKET_VALUES.joinChannel, { token: token?.token })
  }, [])

  useEffect(() => {
    on(SOCKET_VALUES.privateMessage, async () => {
      await queryClient
        .invalidateQueries(TAGS.message_history)
        .finally(() => handlePlaySound())
      await queryClient.invalidateQueries(TAGS.list_of_chats)
      await queryClient.invalidateQueries(TAGS.state_view_msg)

      return () => {
        off(SOCKET_VALUES.privateMessage)
      }
    })
  }, [])

  useEffect(() => {
    if (OUT_OF_THE_APP) {
      if (isOneWeekHoursDifference) {
        localStorage.removeItem(LOCAL_STORAGE_VALUES.invitationCodeTimeExpired)
        localStorage.removeItem(LOCAL_STORAGE_VALUES.invitationCode)
        localStorage.removeItem(
          LOCAL_STORAGE_VALUES.currentInvitationCodeExpired
        )
      }
    }
  }, [invitationCodeTimeExpired])

  useEffect(() => {
    const img = new Image()
    img.src = lost_conection
    img.onload = () => setLostConectionImage(img.src)
  }, [])

  return (
    <Suspense
      fallback={
        <div className="suspense">
          <Loader />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default AppRouter
