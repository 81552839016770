import ButtonSelect from '../ButtonSelect'
import SearchInput from '../SearchInput'
import Xicon from '../../icons/filters/Xicon'
import { useTranslation } from 'react-i18next'
import useClickOutside from '../../hooks/useClickOutside'
import { useRef } from 'react'

const Dropdown = ({
  isSuperSearch,
  searchTerm,
  setSearchTerm,
  filterValue,
  filteredValue,
  setFilteredValue,
  selectedValues,
  handleDataChange,
  handleDeleteData,
  currentValue,
  setCurrentValue,
  setSelectedValue,
  data,

  showDropdown,
  handleShowDropdown,
  handleOutside,

  text,
}) => {
  const { t } = useTranslation()
  const clickRef = useRef()
  useClickOutside(clickRef, handleOutside)

  return (
    <div className="position-relative super-search-field" ref={clickRef}>
      <ButtonSelect
        textClassName="font-small"
        text={text}
        className={`${
          !isSuperSearch
            ? 'p-2 super-search-category-input'
            : 'bg-on-bg-primary border border-on-bg-Tertiary text-white'
        }`}
        arrowFill={!isSuperSearch ? '#004949' : '#ffffff'}
        onClick={() => handleShowDropdown()}
      />
      {showDropdown && (
        <div
          className="position-absolute font-small rounded bg-white shadow-sm p-2 text-on-bg-primary"
          style={{ zIndex: 100 }}
        >
          <SearchInput
            className="form-control-sm mb-2"
            onChange={(e) => {
              const term = e.target.value
              setSearchTerm(term)

              const dataFiltered = filterValue({
                data: currentValue ?? [],
                term,
              })

              setFilteredValue(dataFiltered)
            }}
            value={searchTerm}
            placeholder={t('search')}
          />

          <div className="overflow-auto mt-3" style={{ height: '150px' }}>
            {filteredValue?.map(({ id, name }) => (
              <div
                key={id}
                className="custom-control custom-checkbox form-check p-0"
                id={id}
                value={searchTerm}
              >
                <input
                  className="me-2 border border-2 border-on-bg-primary mycheck"
                  type="checkbox"
                  value={id}
                  key={id}
                  checked={selectedValues.some(
                    (selectedValue) => selectedValue?.id === id
                  )}
                  onChange={() =>
                    handleDataChange({
                      data: currentValue ?? [],
                      serviceData: data,
                      searchTerm: searchTerm ?? '',
                      id,
                      setSelectedData: setSelectedValue,
                      setCurrentData: setCurrentValue,
                      setFilteredData: setFilteredValue,
                    })
                  }
                />
                <label className="custom-control-label">{name}</label>
              </div>
            ))}
          </div>
          <div>
            <div className="d-flex flex-wrap mt-3 gap-1">
              {selectedValues?.map(({ id, name }) => (
                <span
                  key={id}
                  className="d-flex align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
                >
                  {name}
                  <div
                    onClick={() =>
                      handleDeleteData({
                        id,
                        data: currentValue ?? [],
                        setCurrentData: setCurrentValue,
                        setSelectedData: setSelectedValue,
                      })
                    }
                  >
                    <Xicon className="cursor-pointer" fill="#004949" />
                  </div>
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown

//filtered value => filteredCategories
//selected value => selectedCategories
//currentValue => currentCategories
//data => categoriesData
//setCurrentValue => setCurrentCategories
//setSelectedValue => setSelectedCategories
