const TuneIcon = ({fill = '#004949'}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/tune">
        <mask
          id="mask0_8450_2290"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="17"
          height="16"
        >
          <rect
            id="Bounding box"
            x="0.84375"
            width="16"
            height="16"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_8450_2290)">
          <path
            id="Icon"
            d="M8.17708 14V10H9.51042V11.3333H14.8438V12.6667H9.51042V14H8.17708ZM2.84375 12.6667V11.3333H6.84375V12.6667H2.84375ZM5.51042 10V8.66667H2.84375V7.33333H5.51042V6H6.84375V10H5.51042ZM8.17708 8.66667V7.33333H14.8438V8.66667H8.17708ZM10.8438 6V2H12.1771V3.33333H14.8438V4.66667H12.1771V6H10.8438ZM2.84375 4.66667V3.33333H9.51042V4.66667H2.84375Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export default TuneIcon
