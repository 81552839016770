import withReactContent from 'sweetalert2-react-content'
import Button from '../Button'
import Swal from 'sweetalert2'

const CustomModal = ({
  Image = () => 'Coloca una imagen',
  handlePrimaryButton = () => {},
  handleSecondaryButton = () => {},
  textSecondaryButton = 'secondary',
  textPrimaryButton = 'primary',
  title = 'title',
  description = 'description',
  ShowPrimaryButton = true,
  showSecondaryButton = true,
  isLink = false,
  titleClassName = '',
  classNameTextPrimaryButton = '',
  classNameTextSecondaryButton = '',
  classNameSecondaryButton = '',
  classNamePrimaryButton = '',
  href = '',
  hrefTwo = '',
  targetTwo = '',
  targetOne = '_blank',
  position = 'center',
  customClass = {},
}) => {
  const MySwal = withReactContent(Swal)

  return MySwal.fire({
    html: (
      <div className="text-on-bg-primary">
        <Image />
        <h5
          className={`${titleClassName} text-center text-on-bg-primary fw-bolder`}
        >
          {title}
        </h5>
        <span className="text-center font-small text-on-bg-secondary">
          {description}
        </span>
        <div className="d-flex flex-lg-row flex-column-reverse justify-content-center align-items-center text-on-bg-primary gap-3 mt-4">
          {showSecondaryButton &&
            (isLink ? (
              <a
                className="btn btn-white border border-on-bg-primary p-3"
                href={hrefTwo}
                onClick={handleSecondaryButton}
                target={targetTwo}
              >
                <span className={`${classNameTextSecondaryButton}`}>
                  {textSecondaryButton}
                </span>
              </a>
            ) : (
              <Button
                className={` ${classNameSecondaryButton} btn-white border border-on-bg-primary p-3 text-on-bg-primary`}
                onClick={handleSecondaryButton}
              >
                <span className={`${classNameTextSecondaryButton}`}>
                  {textSecondaryButton}
                </span>
              </Button>
            ))}
          {ShowPrimaryButton &&
            (isLink ? (
              <a
                className="btn btn-bg-CTA-primary p-3 text-on-bg-primary"
                href={href}
                target={targetOne}
                rel="noreferrer"
                onClick={handlePrimaryButton}
              >
                <span className={`${classNameTextPrimaryButton}`}>
                  {textPrimaryButton}
                </span>
              </a>
            ) : (
              <Button
                className={`${classNamePrimaryButton} btn-bg-CTA-primary p-3 text-on-bg-primary`}
                onClick={handlePrimaryButton}
              >
                <span className={`${classNameTextPrimaryButton}`}>
                  {textPrimaryButton}
                </span>
              </Button>
            ))}
        </div>
      </div>
    ),
    showConfirmButton: false,
    showCloseButton: true,
    showClass: {
      popup: 'swal2-show',
      backdrop: 'swal2-backdrop-show modal-custom-styles',
      icon: 'swal2-icon-show',
    },
    customClass,
    position,
  })
}

export default CustomModal
